const EARTH_RADIUS = 6371008.8;

function degreesToRadians(degrees: number): number {
  const radians = degrees % 360;
  return (radians * Math.PI) / 180;
}

function radiansToKmLength(radians: number): number {
  return radians * (EARTH_RADIUS / 1000);
}

type Coordinates = [number, number];

export function coordsToKmDistance(
  fromLatLng: Coordinates,
  toLatLng: Coordinates
) {
  const dLat = degreesToRadians(toLatLng[0] - fromLatLng[0]);
  const dLon = degreesToRadians(toLatLng[1] - fromLatLng[1]);

  const lat1 = degreesToRadians(fromLatLng[0]);
  const lat2 = degreesToRadians(toLatLng[0]);

  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);

  return radiansToKmLength(2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)));
}
