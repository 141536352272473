/* eslint-disable @typescript-eslint/no-var-requires */
import { normalizeTzName } from './timezone';

// use require because star import seems to be very tricky with dayjs in a lib
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const tz = require('dayjs/plugin/timezone');
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(relativeTime);

//* Dates follow ISO 8601

/**
 * matches ISO format `2020-08-24T01:00`
 */
export const ISO_LOCAL_REGEX =
  /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])T(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

/**
 * Get UTC timestamp of passed ISOLocal + timezone
 *
 * __For bookings__:
 * gets UTC time to be used for background schedules
 *
 * @param isoLocal date in local ISO format '2020-08-24T01:00'
 * @param timeZone timezone to consider for isoLocal
 *
 * @return UTC timestamp in milliseconds
 */
export function isoLocalToUtcTimestamp(
  isoLocal: string,
  timeZone: string
): number {
  if (!ISO_LOCAL_REGEX.test(isoLocal)) {
    throw new Error(`Passed date is not in ISO local format: ${isoLocal}`);
  }

  try {
    return dayjs.tz(isoLocal.replace('T', ''), timeZone).valueOf();
  } catch (err) {
    return dayjs
      .tz(isoLocal.replace('T', ''), normalizeTzName(timeZone))
      .valueOf();
  }
}

/**
 * Get UTC date-string of passed ISOLocal + timezone
 *
 * @param isoLocal date in local ISO format '2020-08-24T01:00'
 * @param timeZone timezone to consider for ISOLocal
 *
 * @return string in format of '2020-05'
 */
export function getUtcMonthFromIsoLocal(isoLocal: string, timeZone: string) {
  const dateInMs = isoLocalToUtcTimestamp(isoLocal, timeZone);

  return new Date(dateInMs).toISOString().slice(0, 7);
}

export function getRelativeTime(date: any, lng: string) {
  return dayjs(date).locale(lng).fromNow();
}
