import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill';

// node v12 or higher is required
function polyfillTzLocale(locale: string) {
  if (shouldPolyfill()) {
    require('@formatjs/intl-datetimeformat/polyfill');
  }
  // @ts-expect-error the polyfilled prop exists on polyfilled DateTimeFormat
  if (Intl.DateTimeFormat.polyfilled) {
    require('@formatjs/intl-datetimeformat/add-all-tz');

    switch (locale) {
      default:
        require('@formatjs/intl-datetimeformat/locale-data/en');
        break;
      case 'ia':
        require('@formatjs/intl-datetimeformat/locale-data/ia');
        break;
    }
  }
}

export const normalizeTzName = (tzName: string) => {
  if (tzName.includes('Kyiv')) {
    return 'Europe/Kiev';
  }

  return tzName;
};

const getUtcOffsetInternal = (timeZone: string, date: Date) => {
  let offset = '';

  ['ia', 'en'].some((locale) => {
    polyfillTzLocale(locale);

    // can be: GMT+1, WEST, EEST
    const timeZoneName = Intl.DateTimeFormat(locale, {
      timeZoneName: 'short',
      timeZone
    })
      .formatToParts(date)
      .find((i) => i.type === 'timeZoneName')!.value;

    // timeZoneName starting with GMT or UTC is offset - keep and stop looping
    // Otherwise it's an abbreviation, keep looping
    if (/^(GMT|UTC)/.test(timeZoneName)) {
      offset = timeZoneName.slice(3) || '+0';
      return true;
    }

    if (/^(PST)/.test(timeZoneName)) {
      const pstTimezone = Intl.DateTimeFormat(locale, {
        timeZoneName: 'shortOffset',
        timeZone
      })
        .formatToParts(date)
        .find((i) => i.type === 'timeZoneName')!.value;

      offset = pstTimezone.slice(3) || '-8';

      return true;
    }

    return false;
  });

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) {
    throw new Error(`Сannot parse timezone: ${timeZone}`);
  }

  const [, sign, hour, minute] = matchData;
  let result = parseInt(hour) * 60;

  if (sign === '-') {
    result *= -1;
  }

  if (minute) {
    result += parseInt(minute);
  }

  return result;
};

/**
 * Get timezone UTC offset in minutes
 *
 * @param timeZone target timezone in IANA format
 * @param date date to consider when retrieving timezone. Default is current date
 *
 * @example
 * Consider tz "Europe/Kiev" with current real UTC offset +3
 * const result = getUtcOffset("Europe/Kiev");
 * result === 180 since +3 * 60 = 180
 */
export function getUtcOffsetInMinutes(
  timeZone: string,
  date?: number | Date
): number {
  const d = date ? new Date(date) : new Date();

  try {
    return getUtcOffsetInternal(timeZone, d);
  } catch (err) {
    return getUtcOffsetInternal(normalizeTzName(timeZone), d);
  }
}

/**
 * Get timezone UTC offset
 *
 * @param timeZone target timezone in IANA format
 * @param date date to consider when retrieving timezone. Default is current date
 * @param unit unit to return: "min" - minutes; "ms" - milliseconds. Default is ms
 */
export function getTimezoneOffset(
  timeZone: string,
  date?: number | Date,
  unit: 'ms' | 'min' = 'ms'
): number {
  if (unit === 'ms') {
    return getUtcOffsetInMinutes(timeZone, date) * 60000;
  }

  return getUtcOffsetInMinutes(timeZone, date);
}
