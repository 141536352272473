import { EffectCallback } from 'react';
import { useDeepCompareEffect } from '@/lib/deep-compare/useDeepCompareEffect';
import { createCustomEqual } from 'fast-equals';

const deepCompareEqualsForMaps = createCustomEqual({
  createInternalComparator: (deepEqual) => (a, b, _a, _b, _c, _d, state) => {
    if (a instanceof google.maps.LatLng || b instanceof google.maps.LatLng) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types

    // use fast-equals for other objects
    return deepEqual(a, b, state);
  }
});

export function useDeepCompareEffectForMaps(
  callback: EffectCallback,
  dependencies: any[]
) {
  return useDeepCompareEffect(callback, dependencies, deepCompareEqualsForMaps);
}
