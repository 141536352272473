import { getDateDiffInMs } from './math';
import { ISO_LOCAL_REGEX } from './isoLocal';
import { msPerDay, msPerHour, msPerMinute } from './units';

export type DHM = {
  days: number;
  hours: number;
  minutes: number;
};

export function dateISOToUtcISO(dateStr: string) {
  if (dateStr.includes('Z')) {
    // utc string already
    return dateStr;
  }

  if (!ISO_LOCAL_REGEX.test(dateStr)) {
    throw new Error(
      `Passed date is not in ISO local or UTC format: ${dateStr}`
    );
  }

  return dateStr + ':00Z';
}

/**
 * Calculate number of days, hours, and minutes between two dates.
 *
 * @param {string} pastIso - Date in local ISO format.
 * @param {string} futureIso - Date in local ISO format to compare to
 * `pastIso`
 *
 * @returns Difference in days, hours, and minutes between the two dates.
 */
export function getDHM(pastIso: string, futureIso: string): DHM {
  // prevents DST miscalculations
  const pastIsoUTC = dateISOToUtcISO(pastIso);
  const futureIsoUTC = dateISOToUtcISO(futureIso);

  if (pastIsoUTC > futureIsoUTC) {
    throw new Error('Past date cannot succeed future date');
  }

  const diff = getDateDiffInMs(pastIsoUTC, futureIsoUTC);

  return {
    days: Math.floor(diff / msPerDay),
    hours: Math.floor(diff / msPerHour) % 24,
    minutes: Math.floor(diff / msPerMinute) % 60
  };
}

/**
 * Show day, hours, minutes. If value is 0, it will not be shown.
 *
 * @return string in format: 50 days 1 hours 30 minutes
 */
export function showDHM(
  past: string,
  future: string,
  useDHM?: Record<string, number>
) {
  const dhm = useDHM ?? (getDHM(past, future) as Record<string, number>);

  return Object.keys(dhm)
    .reduce(
      (str, key) =>
        dhm[key] > 0
          ? `${str} ${dhm[key]} ${
              dhm[key] === 1 ? key.slice(0, key.length - 1) : key
            }`
          : str,
      ''
    )
    .trim();
}
