import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';

export enum GoogleMapsInitStatus {
  LOADING = 'LOADING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS'
}

/**
 * Loads Google Maps Api
 */
export const useGoogleMapsApiLoader = (options: LoaderOptions) => {
  const [status, setStatus] = useState(GoogleMapsInitStatus.LOADING);

  useEffect(() => {
    const loader = new Loader(options);

    setStatus(GoogleMapsInitStatus.LOADING);

    loader.load().then(
      () => setStatus(GoogleMapsInitStatus.SUCCESS),
      () => setStatus(GoogleMapsInitStatus.FAILURE)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return status;
};

export interface GoogleMapsApiLoaderProps extends LoaderOptions {
  children?: React.ReactNode;
  /**
   * Fully custom render logic based on google maps loading status.
   */
  render?: (status: GoogleMapsInitStatus) => React.ReactElement;
}

/**
 * Generic component to load Google Maps Api and render children only
 * when it is loaded.
 *
 * Pass `render` prop to set custom renderer based on loading status.
 */
const GoogleMapsApiLoader: React.FC<GoogleMapsApiLoaderProps> = ({
  children,
  render,
  ...options
}) => {
  const status = useGoogleMapsApiLoader(options);

  if (status === GoogleMapsInitStatus.SUCCESS && children)
    return children as any;

  if (render) return render(status);

  return null;
};

export default GoogleMapsApiLoader;
