import Router, { useRouter } from 'next/router';
import { isBrowser } from '@b2w/shared/utility';
import { ISO_LOCAL_REGEX } from '@b2w/shared/dates';
import { SearchVehiclesEmbedOption } from '@b2w/shared/types';
import { coordsToKmDistance } from '@/lib/coordsToKmDistance';
import { rentalAdsService } from '@/lib/services/rental-ads.service';
import { resolveAppHref } from '../AppLink';
import { useEffect, useState } from 'react';

export const SEARCH_PAGE_SIZE = 30;

export enum SortBy {
  recommended = 'recommended',
  priceAsc = 'price_asc'
}

export const sortByOptions = [
  { value: 'recommended', i18nKey: 'search.sort.recommended' },
  { value: 'price_asc', i18nKey: 'search.sort.price' }
];

export async function searchVehicles(
  filter: SearchVehiclesFilter,
  currency?: string
) {
  const isCar = filter.vCategory === 'car';
  const isMoto = filter.vCategory === 'motorcycle';
  const isBike = filter.vCategory === 'bike';

  return rentalAdsService.searchVehicleAds({
    hitsPerPage: SEARCH_PAGE_SIZE,
    embed: [SearchVehiclesEmbedOption.pricing],
    ...(filter.page !== undefined ? { page: filter.page - 1 } : {}),
    ...(filter.aroundLat ? { aroundLat: filter.aroundLat } : {}),
    ...(filter.aroundLng ? { aroundLng: filter.aroundLng } : {}),
    ...(filter.aroundRadiusInKm
      ? { aroundRadiusInKm: filter.aroundRadiusInKm }
      : {}),
    ...(isBike && filter.gears !== undefined ? { gears: filter.gears } : {}),
    ...(isCar && filter.seats !== undefined ? { seats: filter.seats } : {}),
    ...(filter.price !== undefined ? { price: filter.price } : {}),
    ...(filter.from && filter.to
      ? { availableFrom: filter.from, availableTo: filter.to }
      : {}),
    ...(filter.vCategory ? { category: filter.vCategory } : {}),
    ...(filter.driver ? { driver: filter.driver } : {}),
    ...(filter.isVerifiedBusiness
      ? { isVerifiedBusiness: filter.isVerifiedBusiness }
      : {}),
    ...(filter.isUnlimitedKmToDrive
      ? { isUnlimitedKmToDrive: filter.isUnlimitedKmToDrive }
      : {}),
    ...(filter.instantBooking
      ? { isInstantBookingOnly: filter.instantBooking }
      : {}),
    ...(filter.isSuperOwner ? { isSuperOwner: filter.isSuperOwner } : {}),
    ...(isCar && filter.fuel?.length > 0 ? { fuel: filter.fuel } : {}),
    ...(filter.insurance?.length > 0 ? { insurance: filter.insurance } : {}),
    ...(filter.query ? { query: filter.query } : {}),
    ...(filter.sortBy ? { sortBy: filter.sortBy as any } : {}),
    ...((isCar || isMoto) && filter.transmission?.length > 0
      ? { transmission: filter.transmission }
      : {}),
    ...(filter.vType?.length > 0 ? { type: filter.vType } : {}),
    ...(currency ? { currency } : {})
  });
}

function updateQueryStr(updates: Partial<SearchVehiclesFilter>) {
  if (isBrowser) {
    const newState: SearchVehiclesFilter = {
      ...Router.query,
      ...updates
    };

    Router.replace(
      resolveAppHref({ pathname: '/search', query: newState }, Router.query),
      undefined,
      { shallow: true }
    );
  }
}

export function generatePageRange(current: number, lastPage: number) {
  const currentPage = current + 1;
  const delta = 3;

  const range = [];
  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(lastPage - 1, currentPage + delta);
    i += 1
  ) {
    range.push(i);
  }

  if (currentPage - delta > 2) {
    range.unshift('...');
  }
  if (currentPage + delta < lastPage - 1) {
    range.push('...');
  }

  range.unshift(1);
  if (lastPage !== 1) range.push(lastPage);

  return range;
}

export function calculateAroundRadius(
  centerLatLng: [number, number],
  cornerLatLng: [number, number]
) {
  const distance =
    coordsToKmDistance(
      [centerLatLng[0], centerLatLng[1]],
      [cornerLatLng[0], cornerLatLng[1]]
    ) * 0.5;

  return Number(distance.toFixed(2));
}

export function getZoomLevelFromBounds(east: number, west: number) {
  const GLOBE_WIDTH = 256; // a constant in Google's map projection
  let angle = east - west;
  if (angle < 0) {
    angle += 360;
  }
  return Math.round(Math.log((960 * 360) / angle / GLOBE_WIDTH) / Math.LN2);
}

export const MIN_PRICE = 0;
export const MAX_PRICE = 1400;
export const INFINITE_PRICE = 9999999;
export const DEFAULT_PRICE = [MIN_PRICE, INFINITE_PRICE];

// philipino centered
export const DEFAULT_VALS_OUTSIDE_FILTER: Partial<SearchVehiclesFilter> = {
  query: '',
  from: '',
  to: '',
  page: 1,
  pivotLat: 14.6090537,
  pivotLng: 121.0222565,
  zoom: 5,
  aroundLat: 12.559154159728127,
  aroundLng: 124.7245912322283,
  aroundRadiusInKm: 923,
  sortBy: SortBy.recommended
};

export const DEFAULT_VALS: Partial<SearchVehiclesFilter> = {
  fuel: [],
  insurance: [],
  transmission: [],
  vType: [],
  price: DEFAULT_PRICE,
  driver: false,
  isVerifiedBusiness: false,
  isSuperOwner: false,
  isUnlimitedKmToDrive: false,
  instantBooking: false,
  vCategory: undefined,
  gears: undefined,
  seats: undefined
};

export type SearchVehiclesFilter = {
  vType?: string[];
  vCategory?: string;
  price?: number[];
  insurance?: string[];
  driver?: boolean;
  isVerifiedBusiness?: boolean;
  isUnlimitedKmToDrive?: boolean;
  isSuperOwner?: boolean;
  instantBooking?: boolean;
  seats?: number;
  fuel?: string[];
  transmission?: string[];
  from?: string;
  to?: string;
  gears?: number;
  sortBy?: SortBy;
  query?: string;
  /**
   * Current page for results. First page starts from `1`
   */
  page?: number;
  aroundLat?: number;
  aroundLng?: number;
  aroundRadiusInKm?: number;
  pivotLat?: number;
  pivotLng?: number;
  zoom?: number;
};

const paramParser: Record<keyof SearchVehiclesFilter, (val: any) => any> = {
  query: (v) => v || DEFAULT_VALS_OUTSIDE_FILTER.query,
  from: (v) => {
    if (v && ISO_LOCAL_REGEX.test(v)) {
      return v;
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.from;
  },
  to: (v) => {
    if (v && ISO_LOCAL_REGEX.test(v)) {
      return v;
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.to;
  },
  page: (v) => {
    if (v) {
      const parsed = parseInt(v, 10);

      if (parsed && parsed > 0) {
        return parsed;
      }
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.page;
  },
  pivotLat: (v) => {
    if (v) {
      const parsed = parseFloat(v);
      if (parsed) {
        return parsed;
      }
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.pivotLat;
  },
  pivotLng: (v) => {
    if (v) {
      const parsed = parseFloat(v);
      if (parsed) {
        return parsed;
      }
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.pivotLng;
  },
  zoom: (v) => {
    if (v) {
      const parsed = parseInt(v, 10);
      if (parsed && parsed >= 0) {
        return parsed;
      }
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.zoom;
  },
  aroundLat: (v) => {
    if (v) {
      const parsed = parseFloat(v);
      if (parsed) {
        return parsed;
      }
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.aroundLat;
  },
  aroundLng: (v) => {
    if (v) {
      const parsed = parseFloat(v);
      if (parsed) {
        return parsed;
      }
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.aroundLng;
  },
  aroundRadiusInKm: (v) => {
    if (v) {
      const parsed = parseFloat(v);
      if (parsed) {
        return parsed;
      }
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.aroundRadiusInKm;
  },
  driver: (v) => {
    if (v !== undefined) {
      const parsed = parseInt(v, 10);

      if (parsed === 1) {
        return true;
      }
      if (parsed === 0) {
        return false;
      }
    }

    return DEFAULT_VALS.driver;
  },
  isVerifiedBusiness: (v) => {
    if (v !== undefined) {
      const parsed = parseInt(v, 10);

      if (parsed === 1) {
        return true;
      }
      if (parsed === 0) {
        return false;
      }
    }

    return DEFAULT_VALS.isVerifiedBusiness;
  },
  isUnlimitedKmToDrive: (v) => {
    if (v !== undefined) {
      const parsed = parseInt(v, 10);

      if (parsed === 1) {
        return true;
      }
      if (parsed === 0) {
        return false;
      }
    }

    return DEFAULT_VALS.isUnlimitedKmToDrive;
  },
  isSuperOwner: (v) => {
    if (v !== undefined) {
      const parsed = parseInt(v, 10);

      if (parsed === 1) {
        return true;
      }
      if (parsed === 0) {
        return false;
      }
    }

    return DEFAULT_VALS.isSuperOwner;
  },
  fuel: (v) => {
    if (Array.isArray(v)) {
      return v;
    }

    if (v) {
      return v.split(',');
    }

    return DEFAULT_VALS.fuel;
  },
  gears: (v) => {
    if (v) {
      const parsed = parseInt(v, 0);
      if (parsed) {
        return parsed;
      }
    }

    return DEFAULT_VALS.gears;
  },
  instantBooking: (v) => {
    if (v !== undefined) {
      const parsed = parseInt(v, 10);

      if (parsed === 1) {
        return true;
      }
      if (parsed === 0) {
        return false;
      }
    }

    return DEFAULT_VALS.instantBooking;
  },
  insurance: (v) => {
    if (Array.isArray(v)) {
      return v;
    }

    if (v) {
      return v.split(',');
    }

    return DEFAULT_VALS.insurance;
  },
  price: (v) => {
    if (v) {
      const asArr = Array.isArray(v) ? v : v.split(',');
      const [minPrice, maxPrice] = asArr.map((p) => parseFloat(p));

      return [
        isNaN(minPrice) ? MIN_PRICE : minPrice,
        isNaN(maxPrice) ? MAX_PRICE : maxPrice
      ];
    }

    return DEFAULT_VALS.price;
  },
  seats: (v) => {
    if (v) {
      const parsed = parseInt(v, 0);
      if (parsed) {
        return parsed;
      }
    }

    return DEFAULT_VALS.seats;
  },
  sortBy: (v) => {
    if ([SortBy.priceAsc, SortBy.recommended].includes(v)) {
      return v;
    }

    return DEFAULT_VALS_OUTSIDE_FILTER.sortBy;
  },
  transmission: (v) => {
    if (Array.isArray(v)) {
      return v;
    }

    if (v) {
      return v.split(',');
    }

    return DEFAULT_VALS.transmission;
  },
  vCategory: (v) => v || DEFAULT_VALS.vCategory,
  vType: (v) => {
    if (Array.isArray(v)) {
      return v;
    }

    if (v) {
      return v.split(',');
    }

    return DEFAULT_VALS.vType;
  }
};

export function mapAndUpdateQueryParams(currentFilter: SearchVehiclesFilter) {
  const filteredAndMapped = Object.keys(currentFilter).reduce((acc, key) => {
    let val = currentFilter[key];

    if (val === undefined) {
      val = '';
    }

    if (Array.isArray(val)) {
      val = val.join(',');
    } else if (typeof val === 'boolean') {
      val = val ? 1 : 0;
    }

    return { ...acc, [key]: val };
  }, {} as SearchVehiclesFilter);

  updateQueryStr(filteredAndMapped);
}

/**
 * Get initial query string params. Fallback to defaults if not present.
 */
export function parseInitialQueryString(query: Record<string, any>) {
  return Object.keys(paramParser).reduce((acc, paramName) => {
    const processParam = paramParser[paramName];

    try {
      return { ...acc, [paramName]: processParam(query[paramName]) };
    } catch (err) {
      return acc;
    }
  }, {} as SearchVehiclesFilter);
}

export function parseAndUpdateQueryParams(queryParams: Record<string, any>) {
  const initialQueryStr = parseInitialQueryString(queryParams);
  updateQueryStr(initialQueryStr);
  return initialQueryStr;
}

/**
 * React hook which parses initial query params for search page.
 * If some params are missing, default values will be taken, and query string will be updated.
 */
export const useInitialSearchPageQuery = () => {
  const router = useRouter();

  const [initialQuery, setInitialQuery] = useState<SearchVehiclesFilter | null>(
    () => (router?.isReady ? parseAndUpdateQueryParams(router.query) : null)
  );

  useEffect(() => {
    if (router.isReady && !initialQuery) {
      setInitialQuery(parseAndUpdateQueryParams(router.query));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, initialQuery]);

  return initialQuery;
};

export type FilterAction =
  | {
      type: 'SET';
      value: SearchVehiclesFilter;
    }
  | {
      type: 'SET_PROP';
      prop: keyof SearchVehiclesFilter;
      value: SearchVehiclesFilter[keyof SearchVehiclesFilter];
    }
  | {
      type: 'TOGGLE_IN_ARR';
      prop: 'vType' | 'insurance' | 'fuel' | 'transmission';
      value: string;
    }
  | {
      type: 'MERGE';
      value: Partial<SearchVehiclesFilter>;
    };

export const filterReducer = (
  state: SearchVehiclesFilter,
  action: FilterAction
): SearchVehiclesFilter => {
  switch (action.type) {
    case 'SET': {
      return action.value;
    }
    case 'SET_PROP': {
      return { ...state, [action.prop]: action.value };
    }
    case 'TOGGLE_IN_ARR': {
      const index = state[action.prop]?.indexOf(action.value);

      if (index === undefined || (index && index < 0)) {
        return {
          ...state,
          [action.prop]:
            state[action.prop] && state[action.prop].length
              ? [...state[action.prop], action.value]
              : [action.value]
        };
      } else {
        const removed = state[action.prop].filter((_, i) => i !== index);
        return {
          ...state,
          [action.prop]: removed.length === 0 ? undefined : removed
        };
      }
    }
    case 'MERGE': {
      return { ...state, ...action.value };
    }
    default:
      return state;
  }
};

export function getNumberOfAppliedFilters(currentFilter: SearchVehiclesFilter) {
  const count = Object.keys(DEFAULT_VALS).reduce((acc, key) => {
    const val = currentFilter[key];
    const defaultVal = DEFAULT_VALS[key];

    if (
      val === undefined ||
      val === defaultVal ||
      (Array.isArray(val) && val.toString() === defaultVal.toString())
    ) {
      return acc;
    }

    console.log({ val, key });

    return acc + 1;
  }, 0);

  return count;
}
