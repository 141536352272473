import {
  CheckoutPriceResultDTO,
  GetCheckoutPriceQueryDTO,
  GetPreCheckoutPriceQueryDTO,
  SearchVehiclesQueryDTO,
  SearchVehiclesResultDTO,
  PreCheckoutEarningsResultDTO,
  PreCheckoutPriceResultDTO
} from '@b2w/shared/types';
import { MainApiService } from './main-api.service';

// https://stackoverflow.com/a/36978360
class RentalAdsService extends MainApiService {
  private prefix = '/rental-ads';
  private static _instance: RentalAdsService;

  private constructor() {
    super();
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  private endpoint(e: string) {
    return this.prefix + e;
  }

  /**
   * Calculate booking "pre-checkout" price table. Precheckout pricing
   * considers only pickup and return dates.
   */
  async getPreCheckoutPrice(
    query: GetPreCheckoutPriceQueryDTO
  ): Promise<PreCheckoutPriceResultDTO> {
    const endpoint = this.buildEndpointWithQueryString(
      this.endpoint('/precheckout-price'),
      query
    );

    return this.get<PreCheckoutPriceResultDTO>(endpoint, {
      withAuth: false
    });
  }

  /**
   * Calculate booking "pre-checkout" price table to display for owner.
   */
  async getPreCheckoutEarnings(
    query: GetPreCheckoutPriceQueryDTO
  ): Promise<PreCheckoutEarningsResultDTO> {
    const endpoint = this.buildEndpointWithQueryString(
      this.endpoint('/precheckout-earnings'),
      query
    );

    return this.get<PreCheckoutEarningsResultDTO>(endpoint, {
      withAuth: false
    });
  }

  /**
   * Calculate booking checkout price table. Can be used on booking
   * creation page to show price rates.
   */
  async getCheckoutPrice(
    query: GetCheckoutPriceQueryDTO
  ): Promise<CheckoutPriceResultDTO> {
    const endpoint = this.buildEndpointWithQueryString(
      this.endpoint('/checkout-price'),
      query
    );

    return this.get<CheckoutPriceResultDTO>(endpoint, {
      withAuth: true
    });
  }

  /**
   * Get vehicles with variety of filters, including geo-search,
   * pagination, and optional pre-checkout price calculation
   */
  async searchVehicleAds(
    options: Omit<SearchVehiclesQueryDTO, 'currentLocalTimeISO'>
  ): Promise<SearchVehiclesResultDTO> {
    (options as any).currentLocalTimeISO = this.getCurrentDateTimeAsLocalISO();

    const endpoint = this.buildEndpointWithQueryString(
      this.endpoint('/search'),
      options
    );

    return this.get<SearchVehiclesResultDTO>(endpoint, {
      withAuth: false
    });
  }

  private getCurrentDateTimeAsLocalISO() {
    const now = new Date();
    const nowAsUTC = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    return nowAsUTC.slice(0, 16);
  }
}

export const rentalAdsService = RentalAdsService.Instance;
