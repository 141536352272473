import { mergeRefs } from '@/lib/refs';
import { theme } from '@/styles/sc-theme';
import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import { useDeepCompareEffectForMaps } from './useDeepCompareEffectForMaps';

export interface AutoCompleteProps
  extends google.maps.places.AutocompleteOptions {
  id?: string;
  placeholder?: string;
  className?: string;
  /**
   * Whether to clear input element when it is being refocused
   * @default false
   */
  clearOnClick?: boolean;
  /**
   * Specify whether input this component is rendered inside a scrollable
   * container. This option will try to position autocomplete correctly.
   * @default false
   */
  isInsideScrollableContainer?: boolean;
  /** Fires when autocomplete instance was created */
  onAutocompleteCreated?: (
    autocompleteInstance: google.maps.places.Autocomplete
  ) => any;
  /** Fires when autocomplete place changed */
  onPlaceSelected: (
    place: google.maps.places.PlaceResult,
    enteredInputText?: string
  ) => void;
}

const NewAutoComplete = (
  props: AutoCompleteProps,
  externalRef: ForwardedRef<HTMLInputElement>
) => {
  const {
    clearOnClick = false,
    isInsideScrollableContainer = false,
    onPlaceSelected,
    onAutocompleteCreated,
    className,
    placeholder,
    id,
    ...options
  } = props;

  const inputRef = useRef<HTMLInputElement>();
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();

  useEffect(() => {
    if (inputRef.current && !autocomplete) {
      const autoComplete = new google.maps.places.Autocomplete(
        inputRef.current,
        {}
      );
      setAutocomplete(autoComplete);

      if (onAutocompleteCreated) {
        onAutocompleteCreated(autoComplete);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, autocomplete]);

  useDeepCompareEffectForMaps(() => {
    if (autocomplete) {
      autocomplete.setOptions(options);
    }
  }, [autocomplete, options]);

  useEffect(() => {
    if (autocomplete) {
      const listeners: google.maps.MapsEventListener[] = [];

      if (onPlaceSelected) {
        const listener = autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          if (!place.geometry || !place.geometry.location) {
            // User entered the name of a place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            return;
          }

          onPlaceSelected(place, inputRef.current?.value);
        });

        listeners.push(listener);
      }

      return () => {
        listeners.forEach((l) => {
          l.remove();
        });
      };
    }
  }, [autocomplete, onPlaceSelected, inputRef]);

  useEffect(() => {
    let timeout: any;
    const inputNode = inputRef.current;

    const handleInputFocus = () => {
      if (clearOnClick && inputNode) {
        inputRef.current.value = '';
      }

      if (isInsideScrollableContainer) {
        timeout = setTimeout(() => {
          const inputParent = inputNode.parentElement;
          if (inputParent) {
            movePacContainersToElement(inputParent);
          }
        }, 0);
      }
    };

    if (inputNode) {
      inputNode.addEventListener('focus', handleInputFocus);
    }

    return () => {
      inputNode && inputNode.removeEventListener('focus', handleInputFocus);
      timeout && clearTimeout(timeout);
      cleanupPacContainers();
    };
  }, [clearOnClick, isInsideScrollableContainer, inputRef]);

  return (
    <>
      <input
        id={id}
        placeholder={placeholder}
        ref={mergeRefs(externalRef, inputRef)}
        type="text"
        className={className}
      />
      <style jsx global>
        {`
          .pac-container {
            z-index: ${theme.zIndex.alert} !important;
            left: ${isInsideScrollableContainer ? '0px !important' : 'auto'};
            top: ${isInsideScrollableContainer
              ? `${inputRef.current?.offsetHeight ?? 45}px !important`
              : 'auto'};
          }
        `}
      </style>
    </>
  );
};

export default forwardRef(NewAutoComplete);

// ======= utilts

function cleanupPacContainers() {
  const pacContainers = Array.from(document.querySelectorAll('.pac-container'));
  pacContainers.forEach((c) => {
    c.remove();
  });
}

/** */
function movePacContainersToElement(element: HTMLElement) {
  if (element) {
    const pacContainers = Array.from(
      document.querySelectorAll('.pac-container')
    );

    pacContainers.forEach((c) => {
      element.appendChild(c);
    });
  }
}
