import { EffectCallback, useEffect } from 'react';
import { deepEqual } from 'fast-equals';
import { EqualityComparer, useDeepCompareMemoize } from './utils';

/**
 * `useEffect` that uses deep comparison on the dependencies, not reference
 * equality
 */
export function useDeepCompareEffect(
  callback: EffectCallback,
  dependencies: any[],
  /**
   * Custom equality comparer for a single dependency.
   */
  equalityComparer: EqualityComparer = deepEqual
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    callback,
    dependencies.map(useDeepCompareMemoize.bind(equalityComparer))
  );
}
