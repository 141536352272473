import { getTimezoneOffset } from './timezone';
import { msPerHour } from './units';

export function getDateDiffInMs(
  past: string | number | Date,
  future: string | number | Date
) {
  const d1Ms = new Date(past).getTime();
  const d2Ms = new Date(future).getTime();

  return d2Ms - d1Ms;
}

/**
 * Get difference in hours between two millisecond timestamps
 *
 * If there will be 30 minutes difference between timestamps,
 * the result will be rounded up,
 * which means for 30 mins difference will be 1 hour,
 * for 1h30m difference it will be 2 hours
 *
 * @param firstDate_ms
 * @param secondDate_ms (optional)
 * If not used, current time will be used instead
 *
 * @returns number of hours indicating the difference
 *
 */
export function getHourDifferenceBetweenDates(
  firstDate_ms: number,
  secondDate_ms?: number
) {
  const secondDate_ms_ = secondDate_ms ?? Date.now();

  return Math.ceil((firstDate_ms - secondDate_ms_) / msPerHour);
}

/**
 * Get array of Date objects between two Dates
 *
 * @param start
 * @param end
 *
 * @note
 * start date will be always the first element
 *
 * @example
 * const d1 = new Date("2020-12-13");
 * const d2 = new Date("2020-12-18");
 *
 * const result = GetDateArray(d1, d2);
 * result => [DateObj(d1), ..., DateObj(d2)]
 */
export function GetDateArray(start: Date, end: Date) {
  const arr: Date[] = [];

  for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
}

/**
 * Convert Time in seconds to time string
 *
 * @param time TIME in seconds
 *
 * @example
 * // 960 seconds is 16 hours
 * const result = timeString(960);
 * result => "16:00"
 */
export function timeString(time: number) {
  let hours: string | number = Math.floor(time / 60) % 24;
  let minutes: string | number = time % 60;

  if (hours < 10) hours = '0' + hours;
  if (minutes < 10) minutes = '0' + minutes;

  return hours + ':' + minutes;
}

/**
 * Generate array of time strings ['16:00', '17:00', ...]
 *
 * @param _start start time in format 'hh:mm'
 * @param _end end time in format 'hh:mm'
 * @param TIME_STEP optional time step. Default is 60 (which means 60m or 1h)
 *
 * The result will include both *_start* and *_end* params
 */
export function generateTimeArr(_start: string, _end: string, TIME_STEP = 60) {
  const _startSplit = _start.split(':');
  const _endSplit = _end.split(':');

  const start = parseInt(_startSplit[0]) * 60 + parseInt(_startSplit[1]);
  const end = parseInt(_endSplit[0]) * 60 + parseInt(_endSplit[1]);

  const result: string[] = [];

  for (let time = start; time <= end; time += TIME_STEP) {
    result.push(timeString(time));
  }

  return result;
}

type DateTimeObj = {
  [date: string]: string[];
};

/**
 * Generate one hour date-time gaps between two dates
 *
 * First hour in *from* will be included
 *
 * Last hour in *to* will NOT be included
 *
 * @param from date in local ISO format '2020-06-15T18:00'
 * @param to date in local ISO format '2020-08-24T01:00'
 *
 * @example
 * const result = generateGap('2020-12-13T12:00', '2020-12-14T04:00');
 * {
 *   "2020-12-13": ["12:00", "13:00", ... , "23:00"],
 *   "2020-12-14": ["00:00", "01:00", ... , "03:00"]
 * }
 */
export function generateDateTimeObj(from: string, to: string): DateTimeObj {
  const dateFrom = from.slice(0, 10);
  const dateTo = to.slice(0, 10);

  const isoDatesArr = GetDateArray(new Date(dateFrom), new Date(dateTo)).map(
    (v) => v.toISOString().slice(0, 10)
  );

  return isoDatesArr.reduce((acc, date) => {
    let dateTimeArr: string[] = [];
    const isFirstDate = dateFrom === date;
    const isLastDate = dateTo === date;

    if (isFirstDate && isLastDate) {
      const dates = generateTimeArr('00:00', to.slice(11));
      dateTimeArr = generateTimeArr(from.slice(11), dates[dates.length - 2]);
    } else if (isFirstDate) {
      const startTime = from.slice(11);
      dateTimeArr = generateTimeArr(startTime, '23:00');
    } else if (isLastDate) {
      const endTime = to.slice(11);
      const result = generateTimeArr('00:00', endTime);
      dateTimeArr = result.slice(0, result.length - 1);
    } else {
      dateTimeArr = generateTimeArr('00:00', '23:00');
    }

    return { ...acc, [date]: dateTimeArr };
  }, {});
}

/**
 * Get inclusive array of years
 *
 * @param fromYear start year
 * @param toYear end year
 *
 * @example
 * const y1 = 2015;
 * const y2 = 2019;
 *
 * const result = getYearsRange(y1, y2);
 * result => [2015, 2016, 2017, 2018, 2019]
 */
export function getYearsRange(fromYear: number, toYear: number) {
  const fromMonth = new Date(fromYear, 0);
  const toMonth = new Date(toYear, 11);
  const years: number[] = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }
  return years;
}

export function getTodayInTz(timeZone: string) {
  const localNow = new Date();

  // getTimezoneOffset returns negative val for +N timezone, and positive for -N timezone
  // to normalize it, multiply by -1
  // if negative tz, then value will be negative
  // if position tz, then value will be positive
  const localOffsetInMs = localNow.getTimezoneOffset() * 60000 * -1;

  // get actual UTC timestamp
  const localDateAsUTCInMs = localNow.getTime() - localOffsetInMs;
  const offsetInMs = getTimezoneOffset(timeZone, localNow, 'ms');

  return localDateAsUTCInMs + offsetInMs;
}

/**
 * Get date range between 2 dates without considering start and end
 */
export function getExclusiveDateRange(start: Date, end: Date) {
  const arr: Date[] = [];

  for (
    let dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }

  // get rid of First and Last dates
  arr.splice(0, 1);
  arr.splice(arr.length - 1, 1);

  return arr;
}
