import { publicEnv } from '@config';
import GoogleMapsApiLoader, {
  GoogleMapsApiLoaderProps,
  useGoogleMapsApiLoader
} from './GoogleMapsApiLoader';

const appOptions: GoogleMapsApiLoaderProps = {
  language: 'en',
  libraries: ['places'],
  apiKey: publicEnv.gmaps.apiKey
};

export type GmapsWrapperProps = Pick<GoogleMapsApiLoaderProps, 'render'> & {
  children?: React.ReactNode;
};

/**
 * A wrapper around `GoogleMapsApiLoader` which loads maps api with app's
 * config.
 */
const GmapsWrapper: React.FC<GmapsWrapperProps> = ({ children, render }) => {
  return (
    <GoogleMapsApiLoader render={render} {...appOptions}>
      {children}
    </GoogleMapsApiLoader>
  );
};

/**
 * Hook which loads maps api with app's config.
 */
export const useGmapsLoader = () => {
  return useGoogleMapsApiLoader(appOptions);
};

export default GmapsWrapper;
