import { useRef } from 'react';

export type EqualityComparer = (a: any, b: any) => boolean;

/**
 * Used internally by useDeepCompare* hooks
 */
export function useDeepCompareMemoize(this: EqualityComparer, value: any) {
  const ref = useRef();

  if (!this(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
